.bathroomTextBlockElement{
    animation: bathroomTextBlockElement 2000ms forwards;
}

@keyframes bathroomTextBlockElement {
    /* 0% {
        transform: scale(1);
        opacity: 1;
    } */
    45% {
        transform: scale(0.5);
        opacity: 0;
    }
    55% {
        transform: scale(0.5);
        opacity: 0;
    }
    45% {
        transform: scale(1);
        opacity: 1;
    }
}