.effectBigFichePrix {
    animation: effectBigFichePrix 1000ms ease-in forwards;
}

@keyframes effectBigFichePrix {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}