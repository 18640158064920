@media (max-width: 684px) {
    .installationWC {
        position: absolute;
        top: -180px;
    }
    .installationTrayToShower {
        position: absolute;
        top: -170px;
    }
    .installationElectricWaterHeater {
        position: absolute;
        right: -100px;
    }
}