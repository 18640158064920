.blockArticle1 { 
    background-image: url("../../assets/Header/plomberie-depannage.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-origin: content-box;
}
.blockArticle2 { 
    background-image: url("../../assets/Header/plomberie-installation.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-origin: content-box;
}
.blockArticle3 { 
    background-image: url("../../assets/Header/chauffe-eau-electrique.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-origin: content-box;
}
.blockArticle4 { 
    background-image: url("../../assets/Header/climatisation.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position-y: -100px;
}

@media (max-width: 756px) {
    .blockArticle4 { 
        background-position-y: -80px;
    }
    .blockArticle3 { 
        background-position-x: -20px;
    }
}
@media (max-width: 740px) {
    .blockArticle3 { 
        background-position-x: -40px;
    }
}
@media (max-width: 720px) {
    .blockArticle3 { 
        background-position-x: -60px;
    }
}
@media (max-width: 700px) {
    .blockArticle3 { 
        background-position-x: -80px;
    }
}
@media (max-width: 680px) {
    .blockArticle3 { 
        background-position-x: -100px;
    }
}
@media (max-width: 660px) {
    .blockArticle3 { 
        background-position-x: -120px;
    }
}
@media (max-width: 640px) {
    .blockArticle3 { 
        background-position-x: -140px;
    }
}

    @media (max-width: 620px) {
    .blockArticle4 { 
        background-position-y: -60px;
    }
    .blockArticle3 { 
        background-position-x: -200px;
    }
}
@media (max-width: 580px) {
    .blockArticle3 { 
        background-position-x: -220px;
    }
}
@media (max-width: 540px) {
    .blockArticle3 { 
        background-position-x: -240px;
    }
}
@media (max-width: 520px) {
    .blockArticle3 { 
        background-position-x: -260px;
    }
}
@media (max-width: 500px) {
    .blockArticle3 { 
        background-position-x: -280px;
    }
}
@media (max-width: 480px) {
    .blockArticle3 { 
        background-position-x: -160px;
    }
}
@media (max-width: 460px) {
    .blockArticle4 { 
        background-position-y: -40px;
    }
    .blockArticle3 { 
        background-position-x: -200px;
    }
}
@media (max-width: 424px) {
    .blockArticle3 { 
        background-position-x: -220px;
    }
}
@media (max-width: 410px) {
    .blockArticle3 { 
        background-position-x: -240px;
    }
}
@media (max-width: 384px) {
    .blockArticle3 { 
        background-position-x: -260px;
    }
}
@media (max-width: 364px) {
    .blockArticle3 { 
        background-position-x: -280px;
    }
}
@media (max-width: 344px) {
    .blockArticle3 { 
        background-position-x: -300px;
    }
}
@media (max-width: 324px) {
    .blockArticle3 { 
        background-position-x: -320px;
    }
}
@media (max-width: 304px) {
    .blockArticle3 { 
        background-position-x: -340px;
    }
}
@media (max-width: 278px) {
    .blockArticle3 { 
        background-position-x: -360px;
    }
}
@media (max-height: 480px) {
    .blockArticle3 { 
        background-position-x: 0px;
    }
}