.displayModalForm {
    animation: effectDisplayModal 300ms 150ms forwards;
}

@keyframes effectDisplayModal {
    0%{
        transform: scale(0) translate(-50%, -46%);
    }
    100%{
        transform: scale(1) translate(-50%, -46%);
    }
}

.hiddenModalForm {
    animation: hiddenDisplayModal 450ms 150ms forwards;
}

@keyframes hiddenDisplayModal {
    0%{
        transform: scale(1) translate(-50%, -46%);
    }
    50%{
        transform: scale(1.1) translate(-50%, -46%);
    }
    100%{
        transform: scale(0) translate(-50%, -46%);
    }
}

.displayAndHiddenModalConfirmation {
    animation: hiddenAndDisplayModalConfirmation 4500ms 150ms forwards;
}

@keyframes hiddenAndDisplayModalConfirmation {
    0%{
        transform: scale(0) translate(-50%, -46%);
    }
    5%{
        transform: scale(1) translate(-50%, -46%);
    }
    90%{
        transform: scale(1) translate(-50%, -46%);
    }
    95%{
        transform: scale(1.1) translate(-50%, -46%);
    }
    100%{
        transform: scale(0) translate(-50%, -46%);
    }
}

.displayAndHiddenModalConfirmationError {
    animation: hiddenAndDisplayModalConfirmation 6000ms 150ms forwards;
}

@keyframes hiddenAndDisplayModalConfirmation {
    0%{
        transform: scale(0) translate(-50%, -46%);
    }
    5%{
        transform: scale(1) translate(-50%, -46%);
    }
    90%{
        transform: scale(1) translate(-50%, -46%);
    }
    95%{
        transform: scale(1.1) translate(-50%, -46%);
    }
    100%{
        transform: scale(0) translate(-50%, -46%);
    }
}