.startModal {
  animation: startModal 1s 5s forwards;
}
@keyframes startModal {
  0% {
    opacity: 0;
    transform: translateX(-120%);
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
} 

.blockModalReduction {
  animation: blockModalReduction 250ms ease-in forwards;
}

@keyframes blockModalReduction {
  0%{
    transform: scale(1);
  }
  100%{
    transform: scale(1);
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

.contenairImageModalMini {
  animation: contenairImageModalMini 250ms ease-in forwards;
}
@keyframes contenairImageModalMini {
  0% {
    width: 80px;
    height: 80px;
  }
  20%{
    width: 80px;
    height: 80px;
    top: 10px;
    left: 14px;
  }
  100% {
    width: 50px;
    height: 50px;
    top: -1px;
    left: 0;
  }
}

.blockModalDisplay {
  animation: blockModalDisplay 300ms ease-in forwards;
}

@keyframes blockModalDisplay {
  0%{
    transform: scale(1);
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  20%{
    transform: scale(0.75);
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  100%{
    transform: scale(1);
    width: 440px;
    height: 320px;
    border-radius: 10px;
  }
}


@media (max-width: 490px) {
  @keyframes blockModalDisplay {
    0%{
      transform: scale(1);
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    20%{
      transform: scale(0.75);
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    100%{
      transform: scale(1);
      width: 90%;
      left: 5%;
      border-radius: 10px;
    }
  }
}

@media (max-width: 375px) {
  @keyframes blockModalDisplay {
    0%{
      transform: scale(1);
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    20%{
      transform: scale(0.75);
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    100%{
      transform: scale(1);
      height: 400px;
      border-radius: 10px;
    }
  }
}
@media (max-width: 290px) {
  @keyframes blockModalDisplay {
    0%{
      transform: scale(1);
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    20%{
      transform: scale(0.75);
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    100%{
      transform: scale(1);
      height: 430px;
    }
  }
}
@media (max-width: 274px) {
  @keyframes blockModalDisplay {
    0%{
      transform: scale(1);
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    20%{
      transform: scale(0.75);
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    100%{
      transform: scale(1);
      height: 450px;
    }
  }
}

.contenairImageModalNormal {
  animation: contenairImageModalNormal 300ms ease-in forwards;
}
@keyframes contenairImageModalNormal {
  0% {
    width: 50px;
    height: 50px;
    top: -1px;
    left: 0;
  }
  20%{
    top: -1px;
    left: 0;
  }
  100% {
    width: 80px;
    height: 80px;
    top: 10px;
    left: 14px;
  }
}

.blockMini{
  animation: blockMini 300ms ease-in forwards;
}
@keyframes blockMini {
  0% {
    width: 80px;
    height: 80px;
  }
  100% {
    width: 50px;
    height: 50px;
  }
}

.blockNormal{
  animation: blockNormal 300ms ease-in forwards;
}
@keyframes blockNormal {
  0% {
    width: 50px;
    height: 50px;
  }
  100% {
    width: 80px;
    height: 80px;
  }
}