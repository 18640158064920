@media (max-width: 2330px) {

.displayFilterMiniMenu {
    animation: displayFilterMiniMenu 400ms linear 200ms forwards;
}

@keyframes displayFilterMiniMenu {
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(0%);
    }
}

.displayMiniMenu {
    animation: displayMiniMenu 400ms linear forwards;
}

@keyframes displayMiniMenu {
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(0%);
    }
}

.notDisplayFilterMiniMenu {
    animation: notDisplayFilterMiniMenu 400ms linear forwards;
}

@keyframes notDisplayFilterMiniMenu {
    0%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(100%);
    }
}

.notDisplayMiniMenu {
    animation: notDisplayMiniMenu 400ms linear 200ms forwards;
}

@keyframes notDisplayMiniMenu {
    0%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(100%);
    }
}    
}
