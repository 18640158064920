.apparitionTrash {
    animation: appartionTrash 300ms ease-in forwards;
}

@keyframes appartionTrash {
    0%{
        transform: translateX(-50%) scale(0);
    }
    100%{
        transform: translateX(-50%) scale(1);
    }
}

.notApparitionTrash {
    animation: notApparitionTrash 300ms ease-in forwards;
}

@keyframes notApparitionTrash {
    0%{
        transform: translateX(-50%) scale(1);
    }
    100%{
        transform: translateX(-50%) scale(0);
    }
}

.modalUrgenceMiniTrashed {
    animation: modalUrgenceMiniTrashed 300ms ease-in forwards;
}

@keyframes modalUrgenceMiniTrashed {
    0%{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        transform: scale(1);
    }
    100%{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        transform: scale(0);
    }
}