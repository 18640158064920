.transitionPage {
    animation: transitionPage 800ms linear forwards;
}

@keyframes transitionPage {
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(0%);
    }    
}

.notTransitionPage {
    animation: notTransitionPage 800ms linear 1250ms forwards;
}

@keyframes notTransitionPage {
    0%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(-100%);
    }    
}

@media (max-width: 991px) {
    .transitionPage {
    animation: transitionPage 600ms linear forwards;
    }
    .notTransitionPage {
    animation: notTransitionPage 600ms linear 950ms forwards;
    }  
}
@media (max-width: 480px) {
    .transitionPage {
    animation: transitionPage 500ms linear forwards;
    }
    .notTransitionPage {
    animation: notTransitionPage 500ms linear 1050ms forwards;
    }  
}