.effectNotHoverr {
  transform-origin: top;
  animation-name: effectNotHover;
  animation-delay: 300ms;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}

@keyframes effectNotHover {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}


